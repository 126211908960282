import { useStaticQuery, graphql } from "gatsby";
import * as React from "react"
import { Helmet } from "react-helmet"

type SeoProps = {
	description?: string,
	lang?: string,
	meta?: any[],
	// title: string,
}

const Seo: React.FC<SeoProps> = ({
	// title,
	description = '',
	lang = "en",
	meta = []
}) => {
	const data = useStaticQuery(graphql`
		query SeoTitleQuery {
			site {
				siteMetadata {
					title
				}
			}
		}
	`);

	const metaDescription = description;
	const defaultTitle = data.site.siteMetadata?.title;

	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			title={defaultTitle}
			titleTemplate={defaultTitle}
			meta={[
				{
					name: `description`,
					content: metaDescription,
				},
				{
					property: `og:title`,
					content: defaultTitle,
				},
				{
					property: `og:description`,
					content: metaDescription,
				},
				{
					property: `og:type`,
					content: `website`,
				},
				{
					name: `twitter:card`,
					content: `summary`,
				},
				{
					name: `twitter:title`,
					content: defaultTitle,
				},
				{
					name: `twitter:description`,
					content: metaDescription,
				},
			].concat(meta)}
		/>
	)
}

export default Seo
