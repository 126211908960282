import classNames from "classnames";
import { GatsbyLinkProps, Link } from "gatsby";
import { Link as SmoothLink } from "react-scroll";
import React from "react";

type LinkButtonProps = {
    active?: boolean,
	smoothScroll?: boolean
} & GatsbyLinkProps<{}>;

export const LinkButton: React.FC<LinkButtonProps> = ({
	active,
	smoothScroll,
	children,
	...linkProps
}) => {
	const LinkComponent = smoothScroll ? SmoothLink : Link;

	if(smoothScroll) {
		// @ts-ignore
		linkProps.smooth = true;
	}

    return <LinkComponent
		{...linkProps}
		className={classNames(
			"px-6 py-[10px] inline-block border-[3px] border-accent rounded-md text-accent text-center font-bold transition-colors duration-300",
			active ? 'bg-accent text-white' : 'hover:bg-accent hover:text-white',
			linkProps.className
		)}

	>
        {children}
    </LinkComponent>;
}
