import { useCallback, useEffect, useState } from "react"

const isBrowser = typeof window !== "undefined";

export const useSticky = (ref: React.MutableRefObject<HTMLElement>) => {
	if(!isBrowser)
		return false;

	const [isSticky, setIsSticky] = useState(false);
  
	// mount 
	useEffect(()=>{
		if(!ref.current)
		return

		const cachedRef = ref.current,
		observer = new IntersectionObserver(
			([e]) => setIsSticky(e.intersectionRatio < 1),
			{
				threshold: [1],
				rootMargin: '-1px 0px 0px 0px',
			}
		)

		observer.observe(cachedRef)

		// unmount
		return () => {
			observer.unobserve(cachedRef)
		}
	}, [ref]);

	console.log("Is sticky", isSticky);

	return isSticky;
}
