import classNames from "classnames";
import { Link } from "react-scroll";
import React, { useRef, useState } from "react";
import { useDarkMode } from "usehooks-ts";
import logotype from "../../assets/images/logotype.webp";
import logotypeDark from "../../assets/images/logotype-dark.png";
import { useSticky } from "../../hooks/useSticky";
import { LinkButton } from "../LinkButton";
import menuOptions from "../../assets/images/menu-options.png";

export const Header: React.FC = () => {
	const {isDarkMode} = useDarkMode();
	const headerRef = useRef<undefined | HTMLDivElement>();
	const isSticky = useSticky(headerRef);
	const [isMenuVisible, setIsMenuVisible] = useState(false);

    const menuLinks = [
        {
            url: 'aboutMe',
            text: 'About me',
            button: false
        }, {
            url: 'blog',
            text: 'Blog',
            button: false
        }, {
            url: 'skills',
            text: 'Skills',
            button: false
        }, {
            url: 'portfolio',
            text: 'Portfolio',
            button: false
        }, {
            url: 'contact',
            text: 'Contact',
            button: true
        },
    ];

	const usedLogotype = (isDarkMode && !isSticky ? logotypeDark : logotype);

    return <header ref={headerRef} className={
		classNames(
			"sticky top-0 mt-7 z-[1000]  ",
		)}
	>
		<div className={classNames(
				"py-3 flex justify-between transition-colors container-full",
				isSticky ? "bg-slate-100/75 dark:text-black" : ""
			)}
		>
			<div>
				<Link to="top" smooth>
					<img src={usedLogotype} alt="" className="inline-block align-middle" />
					<div className="ml-4 lg:ml-7 inline-block align-middle font-black text-accent text-xl leading-6">
						KRZYSZTOF<br />
						SZOSTAK
					</div>
				</Link>
			</div>
			<div className="self-center">
				<a href="#" onClick={(e) => {e.preventDefault(); setIsMenuVisible(!isMenuVisible);}} className="block md:hidden"><img src={menuOptions} /></a>
				<ul className="hidden md:block space-x-5 lg:space-x-9 bd:space-x-10">
					{menuLinks.map((link, i) => <li key={i} className="inline-block font-bold">
						{link.button ?
							<LinkButton to={link.url} smoothScroll>{link.text}</LinkButton>
							: <Link to={link.url} smooth>{link.text}</Link>
						}
					</li>)}
				</ul>
			</div>
		</div>
		<ul className={classNames(
				"absolute w-full py-4 md:hidden text-center bg-accent text-white space-y-4 transition-opacity",
				isMenuVisible ? "opacity-100" : "opacity-0 pointer-events-none"
			)}
		>
			{menuLinks.map((link, i) => <li key={i} className="font-bold">
				<Link to={link.url}>{link.text}</Link>
			</li>)}
		</ul>
    </header>;
}
