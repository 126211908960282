import { Link } from "react-scroll";
import React from "react";
import { useDarkMode } from "usehooks-ts";
import logotype from "../../assets/images/logotype.webp";
import logotypeDark from "../../assets/images/logotype-dark.png";
import logotypeLight from "../../assets/images/logotype-light.png";

export const Footer: React.FC = () => {
	const {isDarkMode} = useDarkMode();
	const year = (new Date()).getFullYear();

	const footerItems = [
		{
			url: 'aboutMe',
			text: 'About me'
		}, {
			url: 'blog',
			text: 'Blog'
		}, {
			url: 'skills',
			text: 'Skills'
		}, {
			url: 'portfolio',
			text: 'Portfolio'
		}, {
			url: 'contact',
			text: 'Contact'
		}
	]

	return <footer>
		<div className="bg-accent text-slate-200 px-16">
			<ul className="sm:h-[150px] md:h-[55px] py-6 md:py-0 flex flex-col md:flex-row flex-wrap md:flex-nowrap md:justify-between gap-y-3">
				<li className="mb-5 sm:mb-0 inline-block self-center font-black text-xl leading-none uppercase text-center">
					<Link to="top" smooth>
						<img src={isDarkMode ? logotypeDark : logotype } alt="" className="mx-auto -mt-[80px] mb-10 sm:-mt-[68px] sm:mb-6 block sm:hidden md:block" />
						<img src={logotypeLight} alt="" className="mx-auto mb-2 hidden sm:block md:hidden" />
						Krzysztof<br />
						Szostak
					</Link>
				</li>
				<li className="hidden md:inline-block self-center font-bold opacity-0">&bull;</li>
				{footerItems.map((item, i) => <React.Fragment key={item.text}>
					<li className="inline-block self-center font-bold uppercase">
						<Link to={item.url} smooth>
							{item.text}
						</Link>
					</li>
					{footerItems.length - 1 > i && <li className="hidden md:inline-block self-center font-bold">&bull;</li>}
				</React.Fragment>)}
			</ul>
		</div>
		<div className="hidden md:block text-right py-7 container-full">
			Copyright Krzysztof Szostak {year}&copy; /
			{' '}Design by SGD&copy; /
			{' '}Coded by <a href="https://happy-lynx.com/" target="_blank">Happy Lynx</a>&copy;
		</div>
		<div className="sm:flex md:hidden justify-between py-7 container-full">
			<div className="text-center sm:text-left">Copyright Krzysztof Szostak {year}&copy;</div>
			<div className="text-center sm:text-right">
				Design by SGD&copy;<br />
				Coded by <a href="https://happy-lynx.com/" target="_blank">Happy Lynx</a>&copy;
			</div>
		</div>
	</footer>
}
